import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import Chat from "./features/chat/Chat";
import Downloads from "./features/Downloads";
import NotFound from "./features/errors/NotFound";
import ServerError from "./features/errors/ServerError";
import TestErrors from "./features/errors/TestErrors";
import ForecastDashboard from "./features/forecasts/dashboard/ForecastDashboard";
import GameOfLife from "./features/gameOfLife/GameOfLife";
import HomePage from "./features/home/HomePage";
import ConfirmedEmail from "./features/users/ConfirmedEmail";
import LoginForm from "./features/users/LoginForm";
import Profile from "./features/users/Profile";
import RegisterForm from "./features/users/RegisterForm";
import RegisterSuccess from "./features/users/RegisterSuccess";
import App from "./layout/App";
import RequireAuth from "./RequireAuth";
import GodotGame from "./games/GodotGame"; 

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                element: <RequireAuth />, children: [
                    { path: 'chat', element: <Chat /> },
                    { path: 'forecasts', element: <ForecastDashboard /> },
                    { path: 'errors', element: <TestErrors /> },
                    { path: 'gameOfLife', element: <GameOfLife /> },
                    { path: 'downloads', element: <Downloads /> },
                    { path: 'profile/:username', element: <Profile /> }
                ]
            },
            { path: '', element: <HomePage /> },          
            { path: 'godot-game', element: <GodotGame /> }, 
            { path: 'login', element: <LoginForm /> },
            { path: 'register', element: <RegisterForm /> },
            { path: 'not-found', element: <NotFound /> },
            { path: 'server-error', element: <ServerError /> },
            { path: 'account/registerSuccess', element: <RegisterSuccess /> },
            { path: 'account/verifyEmail', element: <ConfirmedEmail /> },
            { path: '*', element: <Navigate replace to='/not-found' /> },
        ]
    }
];

export const router = createBrowserRouter(routes);
