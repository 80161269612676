import { Button, Item, Label, Segment } from 'semantic-ui-react';
import { WeatherData } from '../../../models/WeatherData';

interface Props {
    forecasts: WeatherData[];
}

export default function ForecastList({ forecasts }: Props)
{
    console.log("IDs:", forecasts.map(f => f.id)); // log the IDs
    return (
        <Segment>
            <Item.Group divided>
                {forecasts.map(forecast => (
                    <Item key={forecast.id}>
                        <Item.Content>
                            <Item.Header as='a'>{forecast.location}</Item.Header>
                            <Item.Meta>{forecast.date!.toString()}</Item.Meta>
                            <Item.Description>
                                <div>{forecast.summary}</div>
                                <div>{forecast.temperatureC} C</div>
                                <div>{forecast.temperatureF} F</div>
                            </Item.Description>
                            <Item.Extra>
                                <Button floated='right' content='View' color='blue' />
                                <Label basic content={forecast.id} />
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                ))}
            </Item.Group>
        </Segment>
    )
}