import { Container } from 'semantic-ui-react';
import NavBar from './NavBar';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { useStore } from '../store/store';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import ModalContainer from '../shared/modals/ModalContainer';
import HomePage from '../features/home/HomePage';

function App()
{
    const [revealContent, setRevealContent] = useState(false);
    const location = useLocation();
    const { commonStore, userStore } = useStore();

    useEffect(() =>
    {
        if (commonStore.token) {
            console.log('token present in local storage');
            userStore.getUser().finally(() => commonStore.setAppLoaded())
        }
        else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, userStore])

    if (!commonStore.appLoaded) return <Loading content='Loading app...' />

    return (
        <>
            <ModalContainer />
            <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
            {location.pathname === '/' ? <HomePage /> : (
                <>
                    <Container style={{ marginTop: '7em' }}>
                        <Outlet />
                    </Container>
                </>
            )}
            <NavBar />
        </>
    );
}

export default observer(App);
