import React, { useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/store';

export default observer(function GameOfLife()
{
    const { gameOfLifeStore } = useStore();

    const grid = gameOfLifeStore.grid;

    const running = gameOfLifeStore.running;

    const runningRef = useRef(running);
    runningRef.current = running;

    const startGame = useCallback(() =>
    {
        if (!runningRef.current) {
            return;
        }

        gameOfLifeStore.handleStart();

        setTimeout(startGame, 100);
    }, [gameOfLifeStore]);

    const handleClick = (i: number, j: number) =>
    {
        gameOfLifeStore.handleClick(i, j);
    };   

    useEffect(() =>
    {
        gameOfLifeStore.handleRandom();
        gameOfLifeStore.handleStart();
    }, [gameOfLifeStore]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: -1 }}>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${gameOfLifeStore.numCols}, 10px)`,
                    width: '100%',
                    height: '100%',
                }}
            >
                {grid.map((rows, i) =>
                    rows.map((col, j) => (
                        <div
                            key={`${i}-${j}`}
                            style={{
                                width: 10,
                                height: 10,
                                backgroundColor: grid[i][j].color,
                                border: 'solid 1px gray',
                            }}
                        />
                    ))
                )}
            </div>
        </div>
    );
});

function getRandomColor()
{
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
