import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Form, Header, Icon, List, Segment } from "semantic-ui-react";
import { useStore } from "../../store/store";

function Chat()
{
    const { messageStore, userStore } = useStore();
    const { messages, createHubConnection, clearMessages, sendMessage } = messageStore;
    const [content, setContent] = useState("");

    const fieldStyle = { width: 'auto', display: 'inline-block', verticalAlign: 'middle' };
    const inputStyle = { width: '100%', display: 'inline-block', verticalAlign: 'middle' };
    const groupStyle = {display: 'flex', alignItems: 'center', }

    useEffect(() =>
    {
        createHubConnection();
        return () => clearMessages();
    }, []);

    const handleSubmit = async (e: any) =>
    {
        e.preventDefault();
        try {
            await sendMessage({
                body: content,
                username: userStore.user?.username!,
            });
            setContent(""); // clear the message content state
        } catch (error) {
            console.log("Error sending message:", error);
        }
    };

    return (
        <div style={{ maxHeight: "100vh", overflow: "hidden" }}>
            <Header inverted as="h2" icon textAlign="center">
                <Icon inverted name="users" circular />
                <Header.Content>Chat</Header.Content>
            </Header>
            <Segment inverted>
                <Segment inverted style={{ height: "400px", overflowY: "scroll" }}>
                    <List inverted relaxed="very">
                        {messages.map((message, index) => (
                            <List.Item key={message.id}>
                                <List.Icon name="user" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header as="a">{message.username}</List.Header>
                                    <List.Description as="a">{message.body}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </Segment>
                <Segment inverted>
                    <Form inverted onSubmit={handleSubmit}>
                        <Form.Group style={groupStyle} widths="equal">
                            <>
                                <List.Icon name="user" size="large" verticalAlign="middle" />
                                <Form.Field style={fieldStyle} label={userStore.user?.displayName} />
                            </>
                            <Form.Input
                                fluid
                                style={inputStyle}
                                name="content"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                control="textarea"
                                rows={4}                                
                            />
                            <Button content="Send" primary />
                        </Form.Group>
                    </Form>
                </Segment>
            </Segment>
        </ div>
    );
}

export default observer(Chat);
