import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { WeatherData } from "../models/WeatherData";

export default class ForecastStore
{
    weatherData: WeatherData[] = [];
    loading = true;
    loadingInitial = true;

    constructor()
    {
        makeAutoObservable(this);
    }

    loadWeatherData = async () =>
    {
        this.setLoadingInitial(true);
        try
        {
            const weatherData = await agent.Forecasts.list();
            this.weatherData = weatherData;
            this.setLoadingInitial(false);
        } catch (error)
        {
            this.setLoadingInitial(false);
            console.log(error);
        }
    }

    setLoadingInitial = (state: boolean) =>
    {
        this.loadingInitial = state;
    };
}