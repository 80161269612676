import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { ChatMessage } from "../models/ChatMessage";
import { store } from "./store";

export default class MessageStore
{
    messages: ChatMessage[] = [];
    hubConnection: HubConnection | null = null;

    constructor()
    {
        makeAutoObservable(this);
    }

    createHubConnection = async () =>
    {      
        const baseURL = process.env.NODE_ENV === 'development'
            ? 'https://localhost:7093/chat'
            : 'https://api.balassanne.com/chat';

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(baseURL, {
                accessTokenFactory: () => store.userStore.user?.accessToken!
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Debug)
            .build();

        try {
            await this.hubConnection.start();
            console.log('Connection started!');
        } catch (err) {
            console.error('Error starting connection:', err);
        }

        this.hubConnection.on('LoadMessages', (messages: ChatMessage[]) =>
        {
            runInAction(() => this.messages = messages);
        });

        this.hubConnection.on('ReceiveMessage', (message: ChatMessage) =>
        {
            runInAction(() => this.messages.push(message));
        });
    }

    stopHubConnection = () =>
    {
        this.hubConnection?.stop().catch(error => console.log('Error stopping connection: ', error));
    }

    clearMessages = () =>
    {
        this.messages = [];
        this.stopHubConnection();
    }

    sendMessage = async (values: any) =>
    {
        try {
            this.hubConnection?.invoke('SendMessage', values).catch(error => console.log('Error sending message: ', error));
        }
        catch (err) {
            console.error('Error sending message:', err);
        }
    }
}
