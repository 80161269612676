import { makeAutoObservable, reaction } from "mobx";
import { ServerError } from "../models/ServerError";

export default class CommonStore
{
	error: ServerError | null = null;
	token: string | null = localStorage.getItem('jwt');
	appLoaded = false;
	revealContent = false;

	constructor()
	{
		makeAutoObservable(this);

		reaction(
			() => this.token, 
			token =>
			{
				if (token)
				{
					localStorage.setItem('jwt', token)
				}
				else
				{
					localStorage.removeItem('jwt')
				}
			}
		)
	}

	setRevealContent(value: boolean)
	{
		this.revealContent = value;
	}

	setServerError(error: ServerError)
	{
		this.error = error;
	}

	setToken = (token: string | null) =>
	{
		if (token) localStorage.setItem('jwt', token);
		this.token = token;
	}
	
	setAppLoaded = () =>
	{
		this.appLoaded = true;
	}
}