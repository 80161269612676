import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ForecastStore from "./forecastStore";
import GameOfLifeStore from "./gameOfLifeStore";
import MessageStore from "./messageStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";

interface Store
{
    commonStore: CommonStore;
    forecastStore: ForecastStore;
    userStore: UserStore;
    modalStore: ModalStore;
    gameOfLifeStore: GameOfLifeStore;
    messageStore: MessageStore;
}

export const store: Store =
{
    commonStore: new CommonStore(),
    forecastStore: new ForecastStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    gameOfLifeStore: new GameOfLifeStore(),
    messageStore: new MessageStore()
}

export const StoreContext = createContext(store);

export function useStore()
{
    return useContext(StoreContext);
}