import { makeAutoObservable } from "mobx";

type Cell = {
    value: boolean;
    color?: string;
};

export default class GameOfLifeStore
{
    numRows = 50;
    numCols = 50;
    grid: Cell[][] = [];
    running = false;
    chartData: { time: number; count: number }[] = [];

    constructor()
    {
        makeAutoObservable(this);
        this.resetGrid();
    }

    // all the 8 possible directions to check for neighbour cells
    private operations = [
        [-1, -1],
        [-1, 0],
        [-1, 1],
        [0, -1],
        [0, 1],
        [1, -1],
        [1, 0],
        [1, 1],
    ];

    // starts the simulation
    private startGame = () =>
    {
        if (!this.running) {
            return;
        }

        this.grid = this.getNextGridState();

        this.chartData.push({
            time: Date.now(),
            count: this.getLivingCellCount(),
        });

        setTimeout(this.startGame, 100);
    };

    //calculates the next state of the grid based on the current state and the rules of the game
    private getNextGridState = (): Cell[][] =>
    {
        return this.grid.map((rows, i) =>
            // create the next state array from the current state array
            rows.map((cell, j) =>
            {
                let neighbors = 0;

                // check all the 8 possible directions for neighbour cells
                this.operations.forEach(([x, y]) =>
                {
                    const newI = i + x;
                    const newJ = j + y;

                    if (
                        // check if the neighbour cell is within the grid
                        newI >= 0 &&
                        newI < this.numRows &&
                        newJ >= 0 &&
                        newJ < this.numCols &&
                        // check if the neighbour cell is alive
                        this.grid[newI][newJ].value
                    ) {
                        // increment the count of living neighbours
                        neighbors += 1;
                    }
                });

                // create a new cell as a dead cell
                const newCell: Cell = {
                    value: false,
                    color: 'black', // keep the old color
                };

                // apply the rules of the game and set the new cell to be alive if needed
                if (neighbors === 3 || (cell.value && neighbors === 2)) {
                    newCell.value = true;
                    newCell.color = cell.value ? cell.color : this.getRandomColor();
                }

                return newCell;
            })
        );
    };

    getRandomColor()
    {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    //gets the count of all living cells (all true / selected)
    private getLivingCellCount = (): number =>
    {
        let count = 0;
        this.grid.forEach((rows) =>
            rows.forEach((cell) =>
            {
                if (cell.value) {
                    count++;
                }
            })
        );
        return count;
    };

    //resets the grid to be clean (all false / unselected)
    resetGrid = () =>
    {
        const rows = [];
        for (let i = 0; i < this.numRows; i++)
        {
            // create a new row of cells
            rows.push(Array.from({ length: this.numCols }, () => ({
                value: false,
                color: undefined,
            })));
        }
        this.grid = rows;
    };

    //handles the click on a specific cell (i, j)
    handleClick = (i: number, j: number) =>
    {
        const cell = this.grid[i][j];
        cell.value = !cell.value;
        cell.color = cell.value ? this.getRandomColor() : 'black';
    };

    //randomly assigns values (and colors) to all cells in the grid
    handleRandom = () =>
    {
        this.grid = this.grid.map((rows) =>
            rows.map((cell) =>
            {
                const newCell = { ...cell };
                newCell.value = Math.random() < 0.5;
                newCell.color = newCell.value ? this.getRandomColor() : 'black';
                return newCell;
            })
        );
    };

    //starting the simulation
    handleStart = () =>
    {
        this.running = true;
        this.startGame();
    };

    //stopping the simulation
    handleStop = () =>
    {
        this.running = false;
    };

    // setting the chart data for the game of life session    
    setChartData = (data: { time: number; count: number }[]) =>
    {
        this.chartData = data;
    };

}
