import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useStore } from '../../store/store';

export default observer(function Profile()
{
    const { userStore: { user, logout, deleteUser } } = useStore();
    const [loading, setLoading] = useState(false);

    function handleDeleteUser()
    {
        setLoading(true);
        deleteUser(user!.username)
            .then(() =>
            {
                setLoading(false);
                logout();
            })
            .catch((error: any) => console.log(error));
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <Segment inverted clearing>
                    <Header content='Profile' floated='left' icon='user' />
                </Segment>
                <Segment inverted>
                    <span>
                        <Icon name='user' />
                        {user!.displayName}
                    </span>
                    <br />
                    <br />
                    <span>
                        <Icon name='envelope' />
                        {user!.email}
                    </span>
                    <br />
                    <br />
                    <Button
                        color='red'
                        floated='left'
                        content='Delete profile'
                        onClick={handleDeleteUser}
                        loading={loading}
                        style={{ marginTop: '10px' }}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
});
