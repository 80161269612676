import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';
import { Container, Image, Menu, Dropdown } from 'semantic-ui-react';
import { useStore } from '../store/store';


export default observer(function NavBar()
{
    const { userStore: { user, userLogout, isLoggedIn }, commonStore } = useStore();
    if (!commonStore.revealContent) return null;

    return (
        <Menu inverted fixed='top' stackable>
            <Container>
                <Menu.Item as={NavLink} to='/' header>
                    <img src="/assets/logo.png" alt="logo" style={{ marginRight: '10px' }} />
                    Balassanne
                </Menu.Item>

                <Menu.Menu position='right'>
                    {isLoggedIn && (
                        <>
                            <Menu.Item as={NavLink} to='/chat' name='Chat' />
                            <Menu.Item as={NavLink} to='/downloads' name='Downloads' />
                            <Menu.Item as={NavLink} to='/gameOfLife' name='Game of Life' />
                            <Menu.Item as={NavLink} to='/forecasts' name='Weather' />
                            <Menu.Item as={NavLink} to='/errors' name='Errors' />
                            <Menu.Item position='right'>
                                <Image src={user?.image} avatar spaced='right' />
                                <Dropdown pointing='top left' text={user?.displayName}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to={`/profile/${user?.username}`} text='My Profile' icon='user' />
                                        <Dropdown.Item onClick={userLogout} text='Logout' icon='power' />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        </>
                    )}
                </Menu.Menu>
            </Container>
        </Menu>
    )
});
