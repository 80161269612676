import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Container, Header, Segment, Image, Button } from "semantic-ui-react";
import { useStore } from '../../store/store';
import LoginForm from '../users/LoginForm';
import RegisterForm from '../users/RegisterForm';
import GameOfLife from '../gameOfLife/GameOfLife';

const HomePage = observer(() =>
{
    const { userStore, modalStore, commonStore } = useStore();
    const [typedText, setTypedText] = useState('');

    useEffect(() =>
    {
        const handleKeydown = (event: KeyboardEvent) =>
        {
            setTypedText((prev) => prev + event.key);
        };
        window.addEventListener('keydown', handleKeydown);
        return () =>
        {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    useEffect(() =>
    {
        if (typedText.includes('balassannedev')) {
            commonStore.setRevealContent(true);
        }
    }, [typedText, commonStore]);

    return (
       <></>
    );
});

export default HomePage;