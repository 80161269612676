import { Button, Header, Segment } from "semantic-ui-react";
import { useState } from "react";
import ValidationError from "./ValidationError";
import agent from "../../api/agent";

export default function TestErrors()
{
    const [errors, setErrors] = useState(null);

    function handleNotFound()
    {
        agent.Errors.notFound()
            .catch(err => console.log(err.response));
    }

    function handleBadRequest()
    {
        agent.Errors.badRequest()
            .catch(err => console.log(err.response));
    }

    function handleServerError()
    {
        agent.Errors.serverError()
            .catch(err => console.log(err.response));
    }

    function handleUnauthorized()
    {
        agent.Errors.unauthorized()
            .catch(err => console.log(err.response));
    }

    function handleBadGuid()
    {
        agent.Errors.badGuid()
            .catch(err => console.log(err.response));
    }

    function handleValidationError()
    {
        agent.Errors.validationError()
            .catch((err) =>
            {
                console.log(err);
                setErrors(err);
            });
    }

    return (
        <>
            <Header as='h1' content='Test Error component' />
            <Segment>
                <Button.Group widths='7'>
                    <Button onClick={handleNotFound} content='Not Found' basic primary />
                    <Button onClick={handleBadRequest} content='Bad Request' basic primary />
                    <Button onClick={handleValidationError} content='Validation Error' basic primary />
                    <Button onClick={handleServerError} content='Server Error' basic primary />
                    <Button onClick={handleUnauthorized} content='Unauthorised' basic primary />
                    <Button onClick={handleBadGuid} content='Bad Guid' basic primary />
                </Button.Group>
            </Segment>
            {errors && <ValidationError errors={ errors } /> }
        </>
    )
}
