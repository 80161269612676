import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import agent from "../../api/agent";
import useQuery from "../../hooks";
import { useStore } from "../../store/store";
import LoginForm from "./LoginForm";

export default function ConfirmedEmail()
{
    const { modalStore } = useStore();
    const email = useQuery().get('email') as string;
    const token = useQuery().get('token') as string;

    const Status = {
        Verifying: 'Verifying',
        Failed: 'Failed',
        Success: 'Success'
    }

    const [status, setStatus] = useState(Status.Verifying);

    useEffect(() =>
    {
        agent.Account.verifyEmail(token, email).then(() =>
        {
            setStatus(Status.Success)
        }).catch(() =>
        {
            setStatus(Status.Failed)
        })
    }, [Status.Verifying, Status.Failed, Status.Success, token, email])


    function handleConfirmEmailResend()
    {
        agent.Account.resendEmailConfirmation(email).then(() =>
        {
            toast.success('Verification email was sent again!');
        }).catch(error => console.log(error));
    }
    function getBody()
    {
        console.log('status is: ', status);
        switch (status)
        {
            case Status.Verifying:
                return <p>Veryfying...</p>
            case Status.Failed:
                return (
                    <div>
                        <p>Verification failed. You can try resending the verify link to your email...</p>
                        <Button primary onClick={handleConfirmEmailResend} size='huge' content='Resend email!' />
                    </div>
                )
            case Status.Success:
                return (
                    <div>
                        <p style={{color: 'black'}}>Email has been verified, you can now login!</p>
                        <Button primary onClick={() => modalStore.openModel(<LoginForm />)} size='huge' content='Resend email!' />
                    </div>
                )
        }       
    }

    return (
        <Segment placeholder textAlign='center'>
            <Header icon>
                <Icon name='envelope' />
            </Header>
            <Segment.Inline>
                {getBody()}
            </Segment.Inline>
        </Segment>
    )
}