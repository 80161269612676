import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { User, UserFormValues } from '../models/User';
import { WeatherData } from '../models/WeatherData';
import { router } from '../Routes';
import { store } from '../store/store';


const baseApiInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
        ? 'https://localhost:7093/'
        : 'https://api.balassanne.com/',
    withCredentials: true
});

const forecastsInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
        ? 'https://localhost:7093/WeatherForecast/'
        : 'https://api.balassanne.com/WeatherForecast/',
    withCredentials: true
});

const accountInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
        ? 'https://localhost:7046/Account/'
        : 'https://identity.balassanne.com/Account/',
    withCredentials: true
});

baseApiInstance.interceptors.response.use(async response =>
{   
    return response;
}, (error: AxiosError) =>
{
    const { data, status, headers } = error.response as AxiosResponse;

    switch (status)
    {
        case 400:            
            router.navigate('/not-found');

            if (data.errors) 
            {
                const modalStateErrors = [];
                for (const key in data.errors) 
                {
                    if (data.errors[key]) 
                    {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            }
            else 
            {
                toast.error(data);
            }
            break;
        case 401:
            toast.error('unauthorized')
            router.navigate('/');
            try {
                if (headers['www-authenticate'].startsWith(`Bearer error="invalid_token"`)) {
                    console.log('invalid access token');
                    store.userStore.logout();
                    toast.error('Session expired - please login again');
                }
            }
            catch (error) {
                toast.error('www-authenticate header missing')
            }           
            break;
        case 403:
            toast.error('forbidden')
            break;
        case 404:
            router.navigate('/not-found');
            toast.error('not-found')
            break;
        case 500:
            store.commonStore.setServerError(data);
            router.navigate('/server-error');
            toast.error('server-error')
            break;
    }

    return Promise.reject(error);
})

accountInstance.interceptors.response.use(response =>
{    
    return response;
}, (error: AxiosError) =>
{
    const { data, status, headers } = error.response as AxiosResponse;

    switch (status) {
        case 400:
            router.navigate('/');
            toast.error('Invalid identity request!');
            break;
        case 401:
            toast.error('unauthorized');
            router.navigate('/');
            console.log('headers', headers);
            if (status === 401 && headers['www-authenticate'].startsWith('Bearer error="invalid_token"')) {
                console.log('invalid access token');
                store.userStore.logout();
                toast.error('Session expired - please login again');
            }          
            break;
        case 403:
            toast.error('forbidden')
            break;
        case 404:
            router.navigate('/not-found');
            toast.error('not-found')
            break;
        case 500:
            store.commonStore.setServerError(data);
            router.navigate('/server-error');
            toast.error('server-error')
            break;
    }

    return Promise.reject(error);
});

forecastsInstance.interceptors.request.use(config =>
{
    const token = store.commonStore.token;
    console.log('1', token);
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
})

baseApiInstance.interceptors.request.use(config =>
{
    const token = store.commonStore.token;
    console.log('2', token);
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
})

accountInstance.interceptors.request.use(config => {
    const token = store.commonStore.token;
    console.log('3', token);
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const forecastsRequests = {
    get: <T>(url: string) => forecastsInstance.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => forecastsInstance.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => forecastsInstance.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => forecastsInstance.delete<T>(url).then(responseBody),
};

const accountRequests = {
    get: <T>(url: string) => accountInstance.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => accountInstance.post<T>(url, body).then(responseBody),
    del: <T>(url: string) => accountInstance.delete<T>(url).then(responseBody),
};

const Forecasts = {
    list: () => forecastsRequests.get<WeatherData[]>('/forecasts')
}

const Account = {
    current: async () =>
    {
        const user = await accountRequests.get<User>('/current');
        return user;
    },
    login: (user: UserFormValues) => accountRequests.post<User>('/login', user),
    logout: async () => accountRequests.post<User>('/logout', {}),
    delete: (user: string) => accountRequests.del<void>(`/delete?username=${user}`),
    register: (user: UserFormValues) => accountRequests.post<User>('/register', user),
    refreshAccessToken: () => accountRequests.post<User>(`/refreshToken`, {}),
    verifyEmail: (token: string, email: string) => accountRequests.post<void>(`/verifyEmail?token=${token}&email=${email}`, {}),
    resendEmailConfirmation: (email: string) => accountRequests.get(`/resendEmailConfirmationLink?email=${email}`)
}

const Errors = {
    notFound: () => baseApiInstance.get('/Error/not-found'),
    badRequest: () => baseApiInstance.get('/Error/bad-request'),
    serverError: () => baseApiInstance.get('/Error/server-error'),
    unauthorized: () => baseApiInstance.get('/Error/unauthorized'),
    badGuid: () => baseApiInstance.get('/WeatherForecast/forecasts/notaguid'),
    validationError: () => baseApiInstance.post('/forecasts', {})
};

const Downloads = {
    downloadFile: (fileName: string) => baseApiInstance.get(`/Downloads/${fileName}`, { responseType: 'blob' }) 
};

const agent = {
    Forecasts,
    Account,
    Errors,
    Downloads
}

export default agent;