import { Button, Card, Image } from 'semantic-ui-react';
import { WeatherData } from '../../../models/WeatherData';

interface Props {
    forecast: WeatherData;
}

export default function ForecastDetails({ forecast }: Props) {
    return (
        <Card fluid>
            <Image src={ `/assets/weatherImages/forecast.png`} wrapped ui={false} />
            <Card.Content>
                <Card.Header>{forecast.location}</Card.Header>
                <Card.Meta>
                    <span className='date'>{forecast.date!.toString()}</span>
                </Card.Meta>
                <Card.Description>
                    <a>
                        <Image src={`/assets/weatherImages/${forecast.summary}.png`} wrapped ui={false} />
                        {forecast.temperatureC} C
                    </a>
                    {forecast.summary}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <Button.Group widths='2'>
                    <Button basic color='blue' content='Edit' />
                    <Button basic color='grey' content='Cancel' />
                </Button.Group>              
            </Card.Content>
        </Card>
    )
}