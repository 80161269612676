import React from 'react';

const GodotGame: React.FC = () =>
{
    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <iframe
                src={`${process.env.PUBLIC_URL}/godot-build/WebGame.html`}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Godot Game"
            ></iframe>
        </div>
    );
};

export default GodotGame;
