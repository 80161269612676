import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import agent from "../api/agent";

export default function Downloads()
{
    const [isDownloading, setIsDownloading] = useState(false);
    const [showTrustMessage, setShowTrustMessage] = useState(false);

    const downloadGameOfLifeScreensaver = async () =>
    {
        setIsDownloading(true);

        try {
            const response = await agent.Downloads.downloadFile("GOLScreensaver.msi");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "GOLScreensaver.msi");
            document.body.appendChild(link);
            link.click();

            setShowTrustMessage(true); // show the trust message after the download is finished
        } catch (error) {
            console.error("Error downloading file:", error);
        }

        setIsDownloading(false);
    };

    return (
        <>
            <Header inverted as="h2" icon textAlign="center">
                <Icon inverted name="download" circular />
                <Header.Content>Downloads</Header.Content>
            </Header>
            <Segment inverted placeholder>
                {showTrustMessage ? (
                    <div style={{ textAlign: "center" }}>
                        <Icon name="check circle outline" size="massive" color="green" />
                        <p style={{ fontSize: "2em", color: "green", fontWeight: "bold" }}>
                            You can trust it, it's not a trojan.
                        </p>
                        <Link to="/" className="ui button primary">
                            Ok
                        </Link>
                    </div>
                ) : (
                    <Segment.Inline>
                        <Button
                            primary
                            disabled={isDownloading}
                            loading={isDownloading}
                            onClick={downloadGameOfLifeScreensaver}
                        >
                            Download Game of Life Screensaver Setup
                        </Button>
                    </Segment.Inline>
                )}
            </Segment>
        </>
    );
}
