import { ErrorMessage, Formik } from "formik";
import * as Yup from 'yup';
import { Button, Form, FormField, Header, Label } from "semantic-ui-react";
import CustomTextInput from "../../shared/form/CustomTextInput";
import { useStore } from "../../store/store";
import ValidationError from "../errors/ValidationError";
import { observer } from "mobx-react-lite";

export default observer(function RegisterForm()
{
    const { userStore } = useStore();

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address!').required('The email is required to register!'),
        username: Yup.string().required('Please set a username!'),
        displayName: Yup.string().required('Please set a display name!'),
        password: Yup.string().required('A password is required!')
    })

    return (
        <Formik
            initialValues={{ displayName: '', username: '', email: '', password: '', error: null }}
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors }) => userStore.register(values).catch((errors) =>
            {
                console.log('chupapi', errors.response.data);
                setErrors(errors.response.data);
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Register to Balassanne' color="teal" textAlign="center" />
                    <CustomTextInput placeholder="Display Name" name='displayName' label='Display name!' />
                    <CustomTextInput placeholder="Username" name='username' label='Username!' />
                    <CustomTextInput placeholder="Email" name='email' label='Email!' />
                    <CustomTextInput placeholder="Password" name='password' type='password' label='Password!' />
                    <ErrorMessage name="error" render={() =>
                    {
                        console.log('errors in ErrorMessage:', errors.error);
                        return <ValidationError errors={errors.error} />;
                    }} />

                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting}
                        positive content='Register'
                        type="submit" fluid                      
                    />
                </Form>
            )}
        </Formik>
    )
})