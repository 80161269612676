import { toast } from "react-toastify";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import agent from "../../api/agent";
import useQuery from "../../hooks";

export default function RegisterSuccess()
{
    const email = useQuery().get('email') as string;

    function handleConfirmEmailResend()
    {
        agent.Account.resendEmailConfirmation(email).then(() =>
        {
            toast.success('Verification email was sent again!');
        }).catch(error => console.log(error));
    }

    return (
        <Segment placeholder textAlign='center'>
            <Header icon color='green'>
                <Icon name='check' />
                Successffully registered!
            </Header>
            <p> Please check you email (including junk email) for the verification email. </p>
            {email &&
                <>
                    <p>Didn't receive the email? Click the below button to resend it!</p>
                    <Button primary onClick={handleConfirmEmailResend} content='Resent email!' size='huge' />
                </>
            }
        </Segment>
    )
}