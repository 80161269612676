import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { User, UserFormValues } from "../models/User";
import { router } from "../Routes";
import { store } from "./store";

export default class UserStore
{
	user: User | null = null;
	refreshTokenTimeout: any = undefined;
	logCounter: number = 0;
	logInterval: any;

	constructor()
	{
		makeAutoObservable(this);
	}

	private startLogCounter(timeout: number)
	{
		this.logCounter = timeout;		
		console.log('starting log counter', timeout);
		this.logInterval = setInterval(() =>
		{
			this.logCounter -= 1000;
			console.log(this.logCounter);
		}, 1000);
	}

	private stopLogCounter()
	{
		clearInterval(this.logInterval);
		this.logCounter = 0;
	}

	get isLoggedIn()
	{
		return !!this.user;
	}

	login = async (creds: UserFormValues) =>
	{
		try {
			const user = await agent.Account.login(creds);

			store.commonStore.setToken(user.accessToken!);
			this.startRefreshAccessTokenTimer(user);

			runInAction(() => this.user = user);
			router.navigate('/forecasts');

			store.modalStore.closeModal();
		}
		catch (error) {
			throw (error);
		}
	}

	register = async (creds: UserFormValues) =>
	{
		console.log('creds', creds);
		const user = await agent.Account.register(creds);
		console.log('user before func', user);
		store.commonStore.setToken(user.accessToken);
		this.startRefreshAccessTokenTimer(user);
		runInAction(() => this.user = user);
		console.log('before redirect');
		router.navigate(`/Account/registerSuccess?email=${creds.email}`);
		store.modalStore.closeModal();
	}

	logout = () =>
	{
		store.commonStore.setToken(null);
		this.user = null;
		router.navigate('/');
	}

	userLogout = async () =>
	{
		this.stopRefreshAccessTokenTimer();
		try {			
			await agent.Account.logout();
			runInAction(() =>
			{
				store.commonStore.setToken(null);
				this.user = null;
				router.navigate('/');
			});
		} catch (error) {
			console.log('error', error);
			throw (error);
		}
	}

	getUser = async () =>
	{
		try {
			agent.Account.refreshAccessToken();
			const user = await agent.Account.current();
			runInAction(() =>
			{
				store.commonStore.setToken(user.accessToken);
				this.startRefreshAccessTokenTimer(user);
				this.user = user;
			});
		}
		catch (error) {
			console.log(error);
		}
	}
	
	refreshAccessToken = async () =>
	{
		console.log('refreshAccessToken called');
		console.log('timeout: ', this.refreshTokenTimeout);

		this.stopRefreshAccessTokenTimer();

		try {
			console.log('refresh token used to get new access token:');
			const user = await agent.Account.refreshAccessToken();
			console.log('refresh token for user:', user);
			runInAction(() => (this.user = user));
			console.log('access token of user:', user.accessToken);
			store.commonStore.setToken(user.accessToken);
			this.startRefreshAccessTokenTimer(user);
		} catch (error) {
			console.log('refresh token error:', error);
		}
	};	

	deleteUser = async (username: string) =>
	{
		agent.Account.delete(username)
			.then(() =>
			{
				this.logout();
			})
			.catch((error: any) => console.log(error));
	};
		
	private startRefreshAccessTokenTimer(user: User)
	{
		console.log('start refresh timer on token');
		const jwtToken = JSON.parse(atob(user.accessToken.split(".")[1]));
		const expires = new Date(jwtToken.exp * 1000);
		console.log('expires at: ', expires.getTime());

		var d = new Date(Date.now());
		var dutc = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());

		const timeout = expires.getTime() - dutc - 40000;
		console.log('timeout is: ', timeout);

		this.refreshTokenTimeout = setTimeout(this.refreshAccessToken, timeout);
		this.startLogCounter(timeout);
	}
	
	private stopRefreshAccessTokenTimer()
	{
		console.log('stopping refresh token timer ', this.refreshTokenTimeout);
		clearTimeout(this.refreshTokenTimeout);
		this.stopLogCounter();
	}
}