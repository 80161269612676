import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import Loading from '../../../layout/Loading';
import { useStore } from '../../../store/store';
import ForecastDetails from '../details/ForecastDetails';
import ForecastList from './ForecastList';

function ForecastDashboard() {
    const { forecastStore } = useStore();

    useEffect(() => {
        forecastStore.loadWeatherData();
    }, [forecastStore])

    if (forecastStore.loadingInitial) return <Loading content='Loading dashboard...' />

    return (
        <Grid>
            <Grid.Column width='10'>
                <ForecastList forecasts={forecastStore.weatherData} />
            </Grid.Column>
            <Grid.Column width='6'>
                {forecastStore.weatherData[0] &&
                    <ForecastDetails forecast={forecastStore.weatherData[0]} />}               
            </Grid.Column>
        </Grid>
    )
}

export default observer(ForecastDashboard);